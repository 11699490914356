export const convertToHHMMSS = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = seconds - hours * 3600 - remainingMinutes * 60;

  const time = new Date();
  time.setHours(hours);
  time.setMinutes(remainingMinutes);
  time.setSeconds(remainingSeconds);

  const formattedTime = time.toTimeString().split(" ")[0];

  return formattedTime;
};

export function getParameterFromURL(parameter: string, url?: string) {
  let params: any = "";

  if (url) {
    params = url.split("?")[1] || "";
  } else {
    params = window.location.search;
  }

  var urlParams = new URLSearchParams(params);
  return urlParams.get(parameter);
}

export const scrollLocation = (selector: string, modifiedLocation?: number) => {
  const locationElement = document.querySelector<HTMLElement>(selector);
  if (locationElement) {
    let currentElement: HTMLElement | null = locationElement;
    let top = 0;

    while (currentElement) {
      top += currentElement.offsetTop;
      currentElement = currentElement.offsetParent as HTMLElement;
    }

    window.scrollTo({ top: top + (modifiedLocation ? modifiedLocation : 0), behavior: "smooth" });
  }
};

export const getDomain = () => {
  if (!process.env.NEXT_PUBLIC_DOMAIN) return "https://ca.hackers.com";

  return process.env.NEXT_PUBLIC_DOMAIN;
};

export function handleInputId(e: React.ChangeEvent<HTMLInputElement>) {
  const { value } = e.target;
  const replaceValue = value.replace(/[^\w-_.]/g, "");

  return replaceValue;
}

export function handleInputKor(e: React.ChangeEvent<HTMLInputElement>) {
  const { value } = e.target;
  const replaceValue = value.replace(
    /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g,
    "",
  );

  return replaceValue;
}

export function handleWithoutKor(e: React.ChangeEvent<HTMLInputElement>) {
  const { value } = e.target;
  const replaceValue = value.replace(
    /[^a-zA-Z0-9\s\!\@\#\$\%\^\&\*\(\)\_\+\=\<\>\,\.\?\;\'\:\"\\\|\}\{\[\]\/]/g,
    "",
  );

  return replaceValue;
}

export const checkMobile = (userAgent: string) => {
  const ua = userAgent.toLowerCase(); //userAgent 값 얻기
  if (ua) {
    if (ua.indexOf("android") > -1) {
      //안드로이드
      return "android";
    } else if (
      ua.indexOf("iphone") > -1 ||
      ua.indexOf("ipad") > -1 ||
      ua.indexOf("ipod") > -1
    ) {
      //IOS
      return "ios";
    } else {
      //아이폰, 안드로이드 외
      return "other";
    }
  }
};


export function maskingName(name: string | any) {
  if (name && name.length <= 2) {
    return name.replace(name.substring(0, 1), "*");
  }

  if (name) {
    return (
      name[0] +
      "*".repeat(name.substring(1, name.length - 1).length) +
      name[name.length - 1]
    );
  }

  return "*"
}

export function handleInputEng(e: React.ChangeEvent<HTMLInputElement>) {
  const { value } = e.target;
  const replaceValue = value.replace(/[^a-zA-Z]/g, "");

  return replaceValue;
}

export const colorMenu = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#9900EF",
  "#000000",
];
export const colorMenuBack = [
  "#ffd5ae",
  "#fff6c8",
  "#e0f8e0",
  "#e0ffe7",
  "#e0fffd",
  "#e0f2ff",
  "#ABB8C3",
  "#ffeafb",
  "#ffeaeb",
  "#000000",
];

export function ensure<T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there.",
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export const reorder = <T extends unknown>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = Array.from<T>(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const isIdPressed = function (v: string) {
  return /^[A-Za-z]{1}[A-Za-z0-9_-]{3,19}$/.test(v) ? true : false;
};

export const isTransferNumber = function (el: HTMLInputElement) {
  if (!isEmpty(el.value)) {
    if (!isNumberPressed(el.value)) {
      alert("숫자만 입력할 수 있습니다.");
      el.value = el.value.replace(/\D/g, "");
    }
  }
};

export const isNumberPressed = function (v: string) {
  return /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(v) ? true : false;
};

// Byte 수 체크 제한
export const fnChkByte = function (obj: HTMLInputElement) {
  let str = obj.value;
  let str_len = str.length;
  let maxByte = obj.maxLength;

  let rbyte = 0;
  let rlen = 0;
  let one_char = "";
  let str2 = "";

  for (let i = 0; i < str_len; i++) {
    one_char = str.charAt(i);
    if (escape(one_char).length > 4) {
      rbyte += 2; //한글2Byte
    } else {
      rbyte++; //영문 등 나머지 1Byte
    }
    if (rbyte <= maxByte) {
      rlen = i + 1; //return할 문자열 갯수
    }
  }
  if (rbyte > maxByte) {
    // alert("한글 "+(maxByte/2)+"자 / 영문 "+maxByte+"자를 초과 입력할 수 없습니다.");
    alert("글자 입력수는" + maxByte + "Byte를 초과할 수 없습니다.");
    str2 = str.substr(0, rlen); //문자열 자르기
    obj.value = str2;
    fnChkByte(obj);
  }
};

export const numberToKorean = function (number: number) {
  // console.log(number);
  let inputNumber = number < 0 ? 0 : number;
  let unitWords = ["", "만", "억", "조", "경"];
  let splitUnit = 10000;
  let splitCount = unitWords.length;
  let resultArray = [];
  let resultString = "";

  for (let i = 0; i < splitCount; i++) {
    let unitResult =
      (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString = String(resultArray[i]) + unitWords[i] + resultString;
  }

  return resultString;
};

export const formatMonthDate = function (a: any, pdivchar: string) {
  let date = a instanceof Date ? a : new Date(a.replace(/-/g, "/"));
  let divchar = pdivchar || "-";
  let week = strMaps["dayNamesShort"][date.getDay()];

  return (
    _zeroFill((date.getMonth() + 1).toString(), 2) +
    divchar +
    _zeroFill(date.getDate().toString(), 2) +
    "(" +
    week +
    ")"
  );
};

export const compareDate = function (a: string, b: string) {
  let endDate = new Date(a);
  let nowDate;
  if (b) {
    nowDate = new Date(b);
  } else {
    nowDate = new Date(currentDate());
  }

  return nowDate > endDate;
};

export const isTransferExpression = function (el: string) {
  let extractTextPattern = /(<([^>]+)>)/gi;

  return el.replace(extractTextPattern, "");
};

export const stringcut = function (str: string, len: number) {
  let s = 0;
  for (let i = 0; i < str.length; i++) {
    s += str.charCodeAt(i) > 128 ? 2 : 1;
    if (s > len) return str.substring(0, i) + "...";
  }
  return str;
};

export const _Redirect = function (
  url: string,
  timeout: number,
  newwin: boolean,
) {
  if (newwin) {
    window.open(url, "_blank");
  } else {
    if (timeout) {
      setTimeout(function () {
        window.location.href = url;
      }, timeout);
    } else {
      window.location.href = url;
    }
  }
};

// export const _randomString = function () {
//   return Math.random().toString(36).substring(7).substr(0, 20);
// };

// export const _createUUID = function () {
//   let d = new Date().getTime();
//   if (window.performance && typeof window.performance.now === "function") {
//     d += performance.now(); //use high-precision timer if available
//   }
//   // let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c)
//   let uuid = "xxxxxxxxxxxx4xxxyxxxxxx".replace(/[xy]/g, function (c) {
//     let r = (d + Math.random() * 16) % 16 | 0;
//     d = Math.floor(d / 16);
//     return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
//   });
//   return uuid;
// };

export const _zeroFill = function (number: string, width: number) {
  if (number == undefined || number == null) return null;
  width -= number.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
  }
  return number + ""; // always return a string
};

export const nullTostring = function (data: any, str: string) {
  // str = str || '';
  if (data == null || isNaN(data)) {
    return str;
  } else {
    return data;
  }
};

export const isEmpty = function (value: any) {
  // 넘어온 값이 빈값인지 체크합니다.
  // !value 하면 생기는 논리적 오류를 제거하기 위해
  // 명시적으로 value == 사용
  // [], {} 도 빈값으로 처리
  if (
    value == "" ||
    value == null ||
    value == undefined ||
    (value != null && typeof value == "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isValidNumber = function (v: number, min: number, max: number) {
  // Check if a valid number is entered
  return v >= min && v <= max ? true : false;
};

export const decimal = function (number: number, precision: number) {
  let str = number.toString();
  let pos = str.indexOf(".");
  if (pos != -1) {
    let tempArr = str.split(".");
    str = tempArr[0] + "." + tempArr[1].substr(0, precision);
  }
  return parseFloat(str);
};

// 3자리마다 콤마 찍기
export const numberWithCommas = function (x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const korLength = function (obj: string) {
  let totalByte = 0;
  let oneChar = "";
  for (let i = 0; i < obj.length; i++) {
    oneChar = obj.charAt(i);
    if (escape(oneChar).length > 4) {
      totalByte += 2;
    } else {
      totalByte++;
    }
  }
  return totalByte;
};

// 사업자번호 체크
export const checkBizID = function (bizID: string) {
  // bizID는 숫자만 10자리로 해서 문자열로 넘긴다.
  let checkID = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5, 1);
  let i,
    chkSum = 0,
    c2,
    remander;
  bizID = bizID.replace(/-/gi, "");

  for (let i = 0; i <= 7; i++) chkSum += checkID[i] * +bizID.charAt(i);
  c2 = "0" + checkID[8] * +bizID.charAt(8);
  c2 = c2.substring(c2.length - 2, c2.length);
  chkSum += Math.floor(+c2.charAt(0)) + Math.floor(+c2.charAt(1));
  remander = (10 - (chkSum % 10)) % 10;

  if (Math.floor(+bizID.charAt(9)) == remander) return true; // OK!

  return false;
};

export const removeItem = function (array: [], item: any) {
  for (let i in array) {
    if (array[i] == item) {
      array.splice(+i, 1);
      break;
    }
  }
};

export const removeHtml = function (contents: string) {
  let array = strMaps.removeTag.htmlTag;

  for (let i in array) {
    let tagRegExp = new RegExp(
      "<" + array[i] + "[^>]*>(.*?)</" + array[i] + ">",
      "ig",
    );
    contents = contents.replace(tagRegExp, "");
  }

  return contents;
};

export const strip_tags = function (input: string, allowed: string) {
  allowed = (
    ((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
  ).join(""); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
    commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  return input
    .replace(commentsAndPhpTags, "")
    .replace(tags, function ($0: string, $1: string) {
      return allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : "";
    });
};

export const currentDate = function () {
  let now = new Date();
  let year = now.getFullYear();
  let mon =
    now.getMonth() + 1 > 9
      ? "" + (now.getMonth() + 1)
      : "0" + (now.getMonth() + 1);
  let day = now.getDate() > 9 ? "" + now.getDate() : "0" + now.getDate();

  return year + "-" + mon + "-" + day;
};

export const currentTime = function () {
  let now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();

  return hours + ":" + minutes;
};

export const createDate = function (d: any) {
  return d instanceof Date ? d : new Date(d.replace(/-/g, "/"));
};

export const addHours = function (date: Date, h: number) {
  date.setHours(date.getHours() + h);
  return date;
};

export const addMinutes = function (date: Date, m: number) {
  date.setMinutes(date.getMinutes() + m);
  return date;
};

export const dayDiffInStr = function (a: any, b: any) {
  let newa = a instanceof Date ? new Date(a) : new Date(a.replace(/-/g, "/"));
  let newb = b instanceof Date ? new Date(b) : new Date(b.replace(/-/g, "/"));
  return newa.setHours(0, 0, 0, 0) - newb.setHours(0, 0, 0, 0) ? false : true;
};

export const diffTime = function (a: any, b: any, t: string) {
  let date1 = a instanceof Date ? a : new Date(a.replace(/-/g, "/"));
  let date2 = b instanceof Date ? b : new Date(b.replace(/-/g, "/"));

  if (date2 < date1) {
    date2.setDate(date2.getDate() + 1);
  }
  let diff = date2.getTime() - date1.getTime();
  switch (t) {
    case "h":
      return Math.ceil(diff / 1000 / 60 / 60);
      break;
    case "m":
      return Math.ceil(diff / 1000 / 60);
      break;
    case "s":
      return Math.ceil(diff / 1000);
      break;
  }
};

export const diffmonth = function (date1: Date, date2: Date) {
  let months =
    (date2.getFullYear() - date1.getFullYear()) * 12 +
    date2.getMonth() -
    date1.getMonth();
  let smonth = date1.getMonth() + 1;
  let emonth = date2.getMonth() + 1;
  return {
    smonth: smonth,
    emonth: emonth,
    tmonth: months,
  };
};

export const formatdate = function (a: any, pdivchar?: string) {
  let date = a instanceof Date ? a : new Date(a.replace(/-/g, "/"));
  let divchar = pdivchar || "-";
  return (
    date.getFullYear() +
    divchar +
    _zeroFill((date.getMonth() + 1).toString(), 2) +
    divchar +
    _zeroFill(date.getDate().toString(), 2)
  );
};

export const dateformatMin = function (date: any) {
  let cdate = date instanceof Date ? date : new Date(date.replace(/-/g, "/"));
  return (
    cdate.getFullYear() +
    "-" +
    _zeroFill((cdate.getMonth() + 1).toString(), 2) +
    "-" +
    _zeroFill(cdate.getDate().toString(), 2) +
    " " +
    _zeroFill(cdate.getHours().toString(), 2) +
    ":" +
    _zeroFill(cdate.getMinutes().toString(), 2)
  );
};

export const dateformatForeignDate = function (date: string) {
  let cdate =
    typeof date == "string" ? new Date(date.replace(/-/g, "/")) : date;
  return (
    _zeroFill(cdate.getDate().toString(), 2) +
    "-" +
    _zeroFill((cdate.getMonth() + 1).toString(), 2) +
    "-" +
    cdate.getFullYear()
  );
};

export const dateformatForeignDatetime = function (date: string) {
  let cdate =
    typeof date == "string" ? new Date(date.replace(/-/g, "/")) : date;
  return (
    _zeroFill(cdate.getHours().toString(), 2) +
    ":" +
    _zeroFill(cdate.getMinutes().toString(), 2) +
    " " +
    _zeroFill(cdate.getDate().toString(), 2) +
    "-" +
    _zeroFill(cdate.getMonth().toString() + 1, 2) +
    "-" +
    cdate.getFullYear()
  );
};

export const dateformatKorDate = function (date: string) {
  let cdate =
    typeof date == "string" ? new Date(date.replace(/-/g, "/")) : date;
  return (
    cdate.getFullYear() +
    "-" +
    _zeroFill((cdate.getMonth() + 1).toString(), 2) +
    "-" +
    _zeroFill(cdate.getDate().toString(), 2)
  );
};

export const dateformatKorDateStr = function (date: string) {
  let cdate =
    typeof date == "string" ? new Date(date.replace(/-/g, "/")) : date;
  return (
    cdate.getFullYear() +
    "년 " +
    _zeroFill(String(cdate.getMonth() + 1), 2) +
    "월 " +
    _zeroFill(cdate.getDate().toString(), 2) +
    "일"
  );
};

export const getWeek = function (cdate: Date, start: number) {
  start = start || 0;
  let today = new Date(cdate.setHours(0, 0, 0, 0));
  let day = today.getDay() - start;
  let date = today.getDate() - day;
  // Grabbing Start/End Dates

  let StartDate = new Date(today.setDate(date));
  let EndDate = new Date(today.setDate(today.getDate() + 6));
  return [StartDate, EndDate];
};

export const weekCount = function (year: number, month_number: number) {
  // month_number is in the range 1..12

  let firstOfMonth = new Date(year, month_number - 1, 1);
  let lastOfMonth = new Date(year, month_number, 0);

  let used = firstOfMonth.getDay() + lastOfMonth.getDate();

  return Math.ceil(used / 7);
};

// export const addDays = function(date,days) {
// 	let cdate = typeof(date) == "string" ? new Date(date.replace(/-/g, "/")) : new Date(date.toString());
// 	cdate.setDate(cdate.getDate() + days);
// 	return cdate;
// };

export const addDays = function (date: string, days: string) {
  let result = new Date(date);
  result.setDate(result.getDate() + parseInt(days, 10));
  return result;
};

//해당하는 날짜가 그 기간안에 있는지
//s: 시작날짜. e:끝 날짜, n:현재 날짜, b: 시작이전(분), p: 추가 시간(분)
export const isInDate = function (
  s: any,
  e: any,
  n: any,
  b: number,
  p: number,
) {
  s =
    typeof s == "string"
      ? new Date(s.replace(/-/g, "/")).setHours(0, 0, 0, 0)
      : s.setHours(0, 0, 0, 0);
  e =
    typeof e == "string"
      ? new Date(e.replace(/-/g, "/")).setHours(23, 59, 59, 0)
      : e.setHours(23, 59, 59, 0);
  n =
    typeof n == "string"
      ? new Date(n.replace(/-/g, "/")).getTime()
      : n.getTime();
  b = typeof b != "number" ? parseInt(b) * 60000 : b * 60000;
  p = typeof p != "number" ? parseInt(p) * 60000 : p * 60000;

  return n > s - b && n < e + p;
};

export const strMaps = {
  monthNames: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  monthNamesShort: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  dayNames: [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ],
  dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
  yearSuffix: "년",
  level: ["관리자", "고객"],
  shortname: [
    "서울",
    "부산",
    "대구",
    "인천",
    "광주",
    "대전",
    "울산",
    "세종",
    "경기",
    "강원",
    "충북",
    "충남",
    "전북",
    "전남",
    "경북",
    "경남",
    "제주",
  ],
  fullname: [
    "서울특별시",
    "부산광역시",
    "대구광역시",
    "인천광역시",
    "광주광역시",
    "대전광역시",
    "울산광역시",
    "세종특별자치시",
    "경기도",
    "강원도",
    "충청북도",
    "충청남도",
    "전라북도",
    "전라남도",
    "경상북도",
    "경상남도",
    "제주특별자치도",
  ],
  areacode: [
    "11",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "36",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "50",
  ],
  paymethod: {
    SC0040: "무통장",
    Free: "무료",
    Card: "신용카드(안심클릭)",
    VCard: "신용카드(ISP)",
    VBank: "무통장입금(가상계좌)",
    DirectBank: "실시간계좌이체",
  },
  removeTag: {
    htmlTag: ["script", "object", "embed"],
  },
};

export const toHHMMSS = function (sec: number) {
  let sec_num = sec;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  let time = hours + "시간 " + minutes + "분 " + seconds + "초";
  if (hours < 1) {
    time = _zeroFill(minutes.toString(), 1) + "분";
  } else {
    time =
      _zeroFill(hours.toString(), 1) +
      "시간 " +
      _zeroFill(minutes.toString(), 0) +
      "분";
  }
  return time;
};

export const percentageToInteger = (count: number, total: number) => {
  if (!total || total === 0 || !count) return "0%";
  return Math.round((count / total) * 100) + "%";
};

// 쿠키생성
export const setCookie = function (
  cName: string,
  cValue: string,
  cDay: number,
) {
  let expire = new Date();
  expire.setDate(expire.getDate() + cDay);
  let cookies = cName + "=" + encodeURIComponent(cValue) + "; path=/ "; // 한글 깨짐을 막기위해 escape(cValue)를 합니다.
  if (typeof cDay != "undefined")
    cookies += ";expires=" + expire.toUTCString() + ";";
  document.cookie = cookies;
};

// 쿠키 가져오기
export const getCookie = function (cName: string) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
      cName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
      "=([^;]*)",
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = function (cName: string) {
  setCookie(cName, "", -1);
};

// 주소에 프로토콜 있는지
export const ensureProtocol = (url: string) => {
  if (/http(s)?\:/.test(url)) {
    return url;
  }

  return `https://${url}`;
}

export const byteToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) return "N/A";

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}

// amplify 공백 치환
export const replacWhiteSpace = (str: string) => {
  return str.toLowerCase().replaceAll(" ", ".|");
}

export const onlyInputNumber = (k: string) => {
  return k.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
};

export const removeBase64Image = async (_content: string) => {
  const base64Image = _content.replace(/<img[^>]+src="data:image\/[^;]+;base64[^"]*"[^>]*>/g, "");
  return base64Image;
}